import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import SDSform, { FormBuilder } from "../../../components/form/form";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import SDSpanel from "../../../components/panel/panel";
import { withAuth } from "../../../security/authorisation";
import { OTPService } from "../../../service/service-auth";

const SecurityDashboard = () => {
    const [qrCode, setQrCode] = useState('');
    const [secret, setSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [configuredOTPs, setConfiguredOTPs] = useState([]);
    const [removing, setRemoving] = useState(false);

    const fetchConfiguredOTPs = () => {
        OTPService.getConfiguredOTPs()
            .then(response => {
                setConfiguredOTPs(response.data);
            })
            .catch(error => {
                console.error('Error fetching configured OTPs:', error);
            });
    };

    const fetchOTPConfig = () => {
        setLoading(true);
        OTPService.getOTPConfig()
            .then(response => {
                if (response.data && response.data.qrCode && response.data.secret) {
                    setQrCode(response.data.qrCode);
                    setSecret(response.data.secret);
                } else {
                    console.error('Invalid response structure:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching OTP data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchOTPConfig();
        fetchConfiguredOTPs();
    }, []);

    const handleRemoveOTP = async (userLabel) => {
        setRemoving(true);
        try {
            await OTPService.removeOTP(userLabel);
            fetchConfiguredOTPs(); // Refresh configured OTPs after removal
            fetchOTPConfig(); // Fetch new OTP config after removal
        } catch (error) {
            console.error('Error removing OTP:', error);
        } finally {
            setRemoving(false);
        }
    };

    const handleSubmit = async (formValues) => {
        setLoading(true);
        setError(false);
        setSuccess(false);
        const otpDeviceDTO = {
            secret,
            deviceName: formValues.deviceName,
            initialCode: formValues.initialCode
        };
        try {
            const response = await OTPService.createOTP(otpDeviceDTO);
            if (response.status === 200) {
                setSuccess(true);
                setQrCode('');
                fetchConfiguredOTPs(); // Refresh configured OTPs
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            console.error('Error creating OTP:', error);
        } finally {
            setLoading(false);
        }
    };

    const config = new FormBuilder()
        .field("initialCode", "6-digit Code").required("Please enter an 6-digit Code as shown on your device")
        .value('')
        .max(6)
        .field("deviceName", "Device Nickname").required("Please enter a Nickname for your Device")
        .value('')
        .max(256)

        .build();

    return (
        <div>
            <div>
                {error && (
                    <div className="alert alert-danger" role="alert">
                        Error setting up Multifactor Authenticator. Please check the fields and try again.
                    </div>
                )}
                {success && (
                    <div className="alert alert-success" role="alert">
                        Multifactor authenticator added successfully!
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <SDSlandingPageTitle byLine='Manage security'>
                        Security
                    </SDSlandingPageTitle>
                </div>
            </div>
            <div className="row panel-container">
                <div className="col-xs-12 col-sm-6">
                    <SDSpanel title="Setup Multi-factor authentication">
                        {loading ? (
                            <Spinner animation="border" />
                        ) : (
                            <>
                                {configuredOTPs.length < 1 ? (
                                    <>
                                        <div>Add multi-factor authentication to further secure your account.</div>
                                        <p></p>
                                        <div>1. Download any of these authenticator apps on your phone or tablet from your app store:
                                            <ul>
                                                <li>FreeOTP</li>
                                                <li>Google Authenticator</li>
                                                <li>Microsoft Authenticator</li>

                                            </ul>
                                        </div>
                                        <div></div>
                                        <div>2. Use your authenticator app to scan the QR code below.</div>

                                        <div className="center-content">
                                            <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
                                        </div>
                                        <p> </p>
                                        <div>3. The authenticator app will show a security code. Enter the 6-digit code.</div>
                                        <p> </p>
                                        <SDSform
                                            config={config}
                                            progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1" />}
                                            disableSubmit={loading}
                                            submitHandler={handleSubmit}
                                            submitLabel={'Submit'}
                                            cancelHandler={() => { }}
                                            cancelRoute={'/home'}
                                        >

                                        </SDSform>
                                    </>
                                ) : (
                                    <>
                                        <div>Multi-factor authentication is enabled.</div>

                                        <div>
                                            <h3>Configured Authenticators</h3>
                                            <p> </p>
                                            <ul>
                                                {configuredOTPs.map((otp, index) => {
                                                    return (
                                                        <li key={otp.userLabel} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <div style={{ marginBottom: '5px' }}>
                                                                    Name: {otp.userLabel}
                                                                </div>
                                                                <div style={{ marginBottom: '5px' }}>
                                                                    Credential Type: {otp.credentialType === 'otp' ? 'Mobile Authenticator App' : otp.credentialType}
                                                                </div>
                                                            </div>
                                                            {removing ? (
                                                                <Spinner animation="border" />
                                                            ) : (
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-secondary"
                                                                    onClick={() => handleRemoveOTP(otp.userLabel)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>

                                    </>
                                )}
                            </>
                        )}
                    </SDSpanel>
                </div>
            </div>
        </div>
    );
};

SecurityDashboard.propTypes = {};

export default withAuth(SecurityDashboard);